import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiCompaniesFetch } from '@/services/DataService'; // Update the service function name
import { setCompanies, setLoading, setError, CompaniesState } from '@/store/slices/data/companiesSlice'; // Update the slice import
import useAuth from './useAuth';
import { useAppSelector } from '@/store';

const useCompaniesFetch = () => {
    const dispatch = useDispatch();
    const { authenticated } = useAuth();
    const companiesState = useAppSelector((state) => state.data.companiesState); // Update the state selector

    const fetchCompanies = async () => {
        dispatch(setLoading(true));
        try {
            const response = await apiCompaniesFetch(); // Update the service call
            dispatch(setCompanies(response.data)); // Adjust based on your API response
            dispatch(setLoading(false));
        } catch (error: any) {
            dispatch(setError(error.message));
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (authenticated && companiesState.companies?.length === 0) { // Update the condition
            fetchCompanies();
        }
    }, [dispatch, authenticated, companiesState.companies?.length]); // Update the dependency

    return companiesState as CompaniesState; // Update the return type
};

export default useCompaniesFetch;
