import { useMemo, lazy, Suspense } from 'react'
import Loading from '@/components/shared/Loading'
import { useAppSelector } from '@/store'
import {
    LAYOUT_TYPE_CLASSIC,
    LAYOUT_TYPE_MODERN,
    LAYOUT_TYPE_SIMPLE,
    LAYOUT_TYPE_STACKED_SIDE,
    LAYOUT_TYPE_DECKED,
    LAYOUT_TYPE_BLANK,
} from '@/constants/theme.constant'
import useAuth from '@/utils/hooks/useAuth'
import useDirection from '@/utils/hooks/useDirection'
import useLocale from '@/utils/hooks/useLocale'
import usePartnersFetch from '@/utils/hooks/usePartnersFetch'
import useDomainsFetch from '@/utils/hooks/useDomainsFetch'
import useScriptsFetch from '@/utils/hooks/useScriptsFetch'
import useCurrenciesFetch from '@/utils/hooks/useCurrenciesFetch'
import useIndustriesFetch from '@/utils/hooks/useIndustriesFetch'
import useAutomationsFetch from '@/utils/hooks/useAutomationsFetch'
import useCompaniesFetch from '@/utils/hooks/useCompaniesFetch'
import { useUser } from '@/kidon/hooks/useUser'

const layouts = {
    [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
    [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
    [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
    [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
    [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
    [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
}

const Layout = () => {
    
    const layoutType = useAppSelector((state) => state.theme.layout.type)
    const { authenticated } = useAuth()
    usePartnersFetch()
    useDomainsFetch()
    useScriptsFetch()
    useCurrenciesFetch()
    useIndustriesFetch()
    useAutomationsFetch()
    useCompaniesFetch()
   
    useDirection()
    useLocale()

    const AppLayout = useMemo(() => {
        if (authenticated) {
            return layouts[layoutType]
        }
        return lazy(() => import('./AuthLayout'))
    }, [layoutType, authenticated])

    

    return (
        <Suspense
            fallback={
                <div className="flex flex-auto flex-col h-[100vh]">
                    <Loading loading={true} />
                </div>
            }
        >
            <AppLayout />
        </Suspense>
    )
}

export default Layout
