import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { apiPartnersFetch } from '@/services/DataService';
import { setPartners, setLoading, setError } from '@/store/slices/data/partnersSlice';
import useAuth from './useAuth';
import { useAppSelector } from '@/store';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';

const usePartnersFetch = (forceFetch?: boolean ) => {
    const dispatch = useDispatch();
    const { authenticated } = useAuth();
    const partnersState = useAppSelector((state) => state.data.partnersState)


    useEffect(() => {
        if (authenticated && (partnersState.partners.length === 0 || forceFetch)) {
            fetchPartners(dispatch);
        }
    }, [dispatch, authenticated, partnersState.partners.length]);

    return partnersState;
};

export const fetchPartners = async (dispatch: Dispatch<AnyAction> ) => {
    
    dispatch(setLoading(true));
    try {
        const response = await apiPartnersFetch();
        dispatch(setPartners(response.data[0])); // Adjust based on your API response
        dispatch(setLoading(false));
    } catch (error: any) {
        dispatch(setError(error.message));
        dispatch(setLoading(false));
    }
};


export default usePartnersFetch;
