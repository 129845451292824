import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLICE_BASE_NAME } from './constants';
import type { Company } from '@/@types/data'; // Assuming you have a Company type defined

export type CompaniesState = {
    companies: Company[];
    isLoading: boolean;
    error: string | null;
}

const initialState: CompaniesState = {
    companies: [],
    isLoading: false,
    error: null,
};

const companiesSlice = createSlice({
    name: `${SLICE_BASE_NAME}/companies`,
    initialState,
    reducers: {
        setCompanies: (state, action: PayloadAction<Company[]>) => {
            state.companies = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
        updateCompanyRedux: (state, action: PayloadAction<Company>) => {
            const index = state.companies.findIndex(company => company.id === action.payload.id);
            if (index !== -1) state.companies[index] = action.payload;
            
        },
        addCompanyRedux: (state, action: PayloadAction<Company>) => {
            state.companies.push(action.payload);
        }
    },
});

export const { setCompanies, setLoading, setError, updateCompanyRedux, addCompanyRedux } = companiesSlice.actions;

export default companiesSlice.reducer;
