import ApiService from './ApiService'

export async function apiPartnersFetch() {
    console.log('apiPartnersFetch =>')
    return ApiService.fetchData<any>({
        url: '/partner/list',
        method: 'get',
        params: {
            query: {
                sortBy: undefined,
                pageSize: 2000,
                pageIndex: 0,
                filters: undefined,
                globalFilters: undefined,
                populate: ["domain", "leadCap"],  // notes
            }
        }
    })
}

export async function apiDomainsFetch() {
    console.log('apiDomainsFetch =>')
    return ApiService.fetchData<any>({
        url: '/domain/list',
        method: 'get',
    })
}

export async function apiIndustriesFetch() {
    console.log('apiIndustriesFetch =>')
    return ApiService.fetchData<any>({
        url: '/industry/list',
        method: 'get',
        params:{
            query:{
                sortBy: undefined,
                pageSize: 2000,
                pageIndex: 0,
                filters: undefined,
                globalFilters: undefined,
                populate: ["domains"]
            }
        }
    })
}

export async function apiCompaniesFetch() {
    console.log('apiCompaniesFetch =>')
    return ApiService.fetchData<any>({
        url: '/company/list',
        method: 'get',
    })
}

export async function apiAutomationsFetch() {
    console.log('apiAutomationsFetch =>')
    return ApiService.fetchData<any>({
        url: '/automation/list',
        method: 'get',
        params: {
            query: {
                sortBy: undefined,
                pageSize: 2000,
                pageIndex: 0,
                filters: undefined,
                globalFilters: undefined,
                populate: ["partner", "partner.domain","etlScript","globalSelectors" ],  // notes
            }
        }
    })
}

export async function apiScriptsFetch() {
    console.log('apiScriptsFetch =>')
    return ApiService.fetchData<any>({
        url: '/scripts/',
        method: 'get',
    })
}

export async function apiCurrenciesFetch() {
    console.log('apiCurrenciesFetch =>')
    return ApiService.fetchData<any>({
        url: '/currency/list',
        method: 'get',
    })
}

export const API_FETCHERS = {
    partners: apiPartnersFetch,
    domains: apiDomainsFetch,
    industries: apiIndustriesFetch,
    companies: apiCompaniesFetch,
    automations: apiAutomationsFetch,
    scripts: apiScriptsFetch,
    currencies: apiCurrenciesFetch,
}