import { combineReducers } from '@reduxjs/toolkit';
import domainsReducer, { DomainsState, setDomains, setLoading as setDomainsLoading, setError as setDomainsError, setError } from './domainsSlice';
import partnerReducer, { PartnersState, setPartners, setLoading as setPartnersLoading, setError as setPartnersError } from './partnersSlice';
import scriptsReducer, { ScriptsState, setScripts, setLoading as setScriptsLoading, setError as setScriptsError } from './scriptsSlice';
import currenciesReducer, { CurrenciesState, setCurrencies, setLoading as setCurrenciesLoading, setError as setCurrenciesError  } from './currenciesSlice';
import industriesReducer, { IndustriesState, setIndustries, setLoading as setIndustriesLoading, setError as setIndustriesError } from './industriesSlice';
import automationsReducer, { AutomationsState, setAutomations, setLoading as setAutomationsLoading, setError as setAutomationsError } from './automationsSlice';
import companiesReducer, {CompaniesState, setCompanies, setLoading as setCompaniesLoading, setError as setCompaniesError} from './companiesSlice';
import localeReducer, { LocaleState } from '../locale/localeSlice';
import { set } from 'lodash';

const reducer = combineReducers({
   partnersState: partnerReducer,
   domainsState: domainsReducer,
   scriptsState: scriptsReducer,
   currenciesState: currenciesReducer,
   industriesState: industriesReducer,
   automationsState: automationsReducer,
   companiesState: companiesReducer,
   localeState: localeReducer,
});

export type DataState = {
   partnersState: PartnersState;  
   domainsState: DomainsState;    
   industriesState: IndustriesState;    
   scriptsState: ScriptsState;    
   currenciesState: CurrenciesState;
   automationsState: AutomationsState;
   companiesState: CompaniesState;
   localeState: LocaleState;
}

export const SETTERS = {
   partners: {setData: setPartners,setLoading: setPartnersLoading, setError: setPartnersError},
   domains: {setData: setDomains, setLoading: setDomainsLoading, setError: setDomainsError},
   industries: {setData: setIndustries, setLoading: setIndustriesLoading, setError: setIndustriesError},
   scripts: {setData: setScripts, setLoading: setScriptsLoading, setError: setScriptsError},
   currencies: {setData: setCurrencies, setLoading: setCurrenciesLoading, setError: setCurrenciesError},
   automations: {setData: setAutomations, setLoading: setAutomationsLoading, setError: setAutomationsError},
   companies: {setData: setCompanies, setLoading: setCompaniesLoading, setError: setCompaniesError},
};


export enum DataNames {
   partners = 'partners',
   domains = 'domains',
   industries = 'industries',
   scripts = 'scripts',
   currencies = 'currencies',
   automations = 'automations',
   companies = 'companies',
}


export * as partnerSlice from './partnersSlice';
export * as domainSlice from './domainsSlice';
export * as industriesSlice from './industriesSlice';
export * as scriptSlice from './scriptsSlice';
export * as currencySlice from './currenciesSlice';
export * as automationSlice from './automationsSlice';
export * as localeSlice from '../locale/localeSlice';

export default reducer;
